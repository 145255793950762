.plan-svg {
  @apply w-full h-auto;
}

#tonte {
  fill: #6f9702;
  &:hover {
    fill: #5baa00;
  }
}

#cloture_portails {
  &:hover {
    .st14 {
      fill: #dcdcdc;
    }
  }
}

#entretien_massifs {
  &:hover {
    path {
      stroke-width: 2px;
    }
  }
}

#acces_cheminement {
  &:hover {
    path {
      fill: #bebebe;
      stroke-width: 4px;
      stroke: white;
    }
  }
}

#mineralisation {
  &:hover {
    path {
      fill: rgb(0, 255, 255);
      stroke: rgba(128, 128, 128, 0.685);
    }
  }
}

#taille {
  &:hover {
    path {
      stroke-width: 2px !important;
    }
  }
}

#brossage {
  &:hover {
    .brossage-passant {
      fill: #bebebe;
    }
    .gazon {
      path {
        stroke-width: 4px;
        fill: #5baa00;
      }
    }
  }
}

#terrasse {
  &:hover {
    .st20 {
      fill: #e6e6e6;
    }
  }
}

.clickable {
  cursor: pointer;
  &:hover {
    stroke: white;
    stroke-width: 4px;
  }
}
