@tailwind base;
@tailwind components;
@tailwind utilities;

// fonts
@import "../assets/fonts/poppins/stylesheet.css";
@import "../assets/fonts/apollo/index.css";

@import "../assets/styles/global.scss";
@import "../assets/styles/plan.scss";

body {
  @apply p-0 m-0 bg-background;
}

.h2-container {
  @apply relative;
  .h2-label {
    @apply inline-block relative z-[1];
    &::after {
      content: "";
      @apply block absolute w-[25px] sm:w-[62px] 2xl:w-[84px] z-0;
      @apply border-b-2 border-flush-orange-500;
      left: calc(100% - 16px) !important;
      @media (min-width: 640px) {
        left: calc(100% - 50px) !important;
      }
      @media (min-width: 1536px) {
        left: calc(100% - 70px) !important;
      }
    }
  }
}

.fixed-hero {
  position: fixed !important;
  width: 100% !important;
  height: 100vh !important;
  transform: translate(0px, 0px) !important;
}
