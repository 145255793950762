@font-face {
  font-family: "APOLLO";
  font-style: normal;
  font-weight: 400;
  src: local("APOLLO"), url("APOLLO.woff") format("woff");
}
@font-face {
  font-family: "APOLLO";
  font-style: italic;
  font-weight: 400;
  src: local("APOLLO"), url("APOLLOItalic.woff") format("woff");
}
